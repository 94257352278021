import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";
import ApiService from "../../network/ApiService";
// import "./Navbar.css";
import NepaliFlag from './svgs/np.svg';
import EnglishFlag from './svgs/us.svg';

function Navbar() {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchMessages,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const {orgDetailData} = useSelector((state) => state.contact);
    const [messages, setMessages] = useState([]);
    const [language, setLanguage] = useState('en');
    const languageStatus = sessionStorage.getItem("language");

    const fetchStaffMessages = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDisplayLabels);
        } catch (error) {
            console.log(error.message);
        }
    };

    const handleShowDropdown = (event) => {
        if (event.target.nextElementSibling !== null) {
            event.target.nextElementSibling.classList.add('show');
        }
    }
    const handleToggleDropdown = (event) => {
        if (event.target.nextElementSibling !== null) {
            event.target.nextElementSibling.classList.toggle('show');
        }
    }

    const handleHideDropdown = () => {
        document.querySelectorAll('.dropdown-menu').forEach(e => e.classList.remove('show'))
    }

    useEffect(() => {
        if (organizationId) {
            fetchStaffMessages();
        }
    }, [organizationId]);

    const changeLanguage = (language: 'en') => {
        const languageStatus = sessionStorage.getItem("language");
        if (languageStatus !== '') {
            setLanguage(language);
            sessionStorage.setItem("language", language);
        }
    }

    return (
        <>
            <div className="wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex align-items-center">
                            {/*<p className="mb-0 phone pl-md-2">*/}
                            {/*    <a href="#" className="mr-2"><span className="fa fa-phone mr-1"></span> +00 1234*/}
                            {/*                                                                            567</a>*/}
                            {/*    <a href="#"><span className="fa fa-paper-plane mr-1"></span>*/}
                            {/*        youremail@email.com</a>*/}
                            {/*</p>*/}
                        </div>
                        <div className="col-md-6 d-flex justify-content-md-end">
                            <div className="social-media">
                                <p className="mb-0 d-flex">
                                    <a target="_blank" href={orgDetailData ? orgDetailData?.facebookUrl : ""} className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-facebook"
                                    ><i className="sr-only">{languageStatus === 'en' ? `Facebook` : 'फेसबुक'}</i></span></a>
                                    <a target="_blank" href={orgDetailData ? orgDetailData?.twitterUrl : ""} className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-twitter"
                                    ><i className="sr-only">{languageStatus === 'en' ? `Twitter` : 'ट्विटर'}</i></span></a>
                                    <a target="_blank" href={orgDetailData ? orgDetailData?.linkedInUrl : ""} className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-linkedin"
                                    ><i className="sr-only">{languageStatus === 'en' ? `LinkedIn` : 'लिङ्क्डइन'}</i></span></a>
                                    <a target="_blank" href={orgDetailData ? orgDetailData?.youtubeUrl : ""} className="d-flex align-items-center justify-content-center"><span
                                        className="fa fa-youtube"
                                    ><i className="sr-only">{languageStatus === 'en' ? `YouTube` : 'युट्युब'}</i></span></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">*/}
            {/*    <div className="container">*/}
            {/*      <Link className="navbar-brand logo" to="/">*/}
            {/*          <img*/}
            {/*              src={*/}
            {/*                  orgDetailData &&*/}
            {/*                  orgDetailData.logoPath &&*/}
            {/*                  orgDetailData.logoPath !== ""*/}
            {/*                      ? orgDetailData.logoPath*/}
            {/*                      : null*/}
            {/*              }*/}
            {/*              alt=""*/}
            {/*              className="img-fluid"*/}
            {/*          />*/}
            {/*      </Link>*/}
            {/*      <button*/}
            {/*          className="navbar-toggler"*/}
            {/*          type="button"*/}
            {/*          data-toggle="collapse"*/}
            {/*          data-target="#ftco-nav"*/}
            {/*          aria-controls="ftco-nav"*/}
            {/*          aria-expanded="false"*/}
            {/*          aria-label="Toggle navigation"*/}
            {/*      >*/}
            {/*        <span className="oi oi-menu"></span> {languageStatus === 'en' ? 'Menu' : `मेनु`}*/}
            {/*      </button>*/}

            {/*      <div className="collapse navbar-collapse" id="ftco-nav">*/}
            {/*        <ul className="nav navbar-nav ml-auto">*/}
            {/*          <li className="nav-item" onMouseOver={handleShowDropdown}><Link*/}
            {/*              to="/"*/}
            {/*              className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*          >{languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}</Link></li>*/}
            {/*          <li*/}
            {/*              className="dropdown nav-item"*/}
            {/*              onClick={handleToggleDropdown}*/}
            {/*              onMouseOver={handleShowDropdown}*/}
            {/*              onMouseLeave={handleHideDropdown}*/}
            {/*          >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/about">{languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}</Link></li>*/}
            {/*                  {Object.keys(messages).length > 0*/}
            {/*                      ? Object.keys(messages).map((key) => {*/}
            {/*                          return (*/}
            {/*                              <li className="nav-item pt-2" key={key}>*/}
            {/*                                  <Link to={`/messages/${key}`}>*/}
            {/*                                      {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}*/}
            {/*                                  </Link>*/}
            {/*                              </li>*/}
            {/*                          );*/}
            {/*                      })*/}
            {/*                      : null}*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*            <li*/}
            {/*                className="dropdown nav-item"*/}
            {/*                onClick={handleToggleDropdown}*/}
            {/*                onMouseOver={handleShowDropdown}*/}
            {/*                onMouseLeave={handleHideDropdown}*/}
            {/*            >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'News/Notices' : `समाचार/सुचना`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/news">समाचार</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/notice">सुचना</Link></li>*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*            <li*/}
            {/*                className="dropdown nav-item"*/}
            {/*                onClick={handleToggleDropdown}*/}
            {/*                onMouseOver={handleShowDropdown}*/}
            {/*                onMouseLeave={handleHideDropdown}*/}
            {/*            >*/}
            {/*              <a*/}
            {/*                  className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}*/}
            {/*                  href="#"*/}
            {/*              >{languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`} <b className="fa fa-chevron-down"></b></a>*/}
            {/*              <ul className="dropdown-menu px-3">*/}
            {/*                <li className="nav-item pt-2"><Link to="/our-services">{languageStatus === 'en' ? 'Our Services' : `हाम्रो सेवाहरु`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/outstanding-report">{languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/complain-service">{languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/water-schedule">{languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}</Link></li>*/}
            {/*                <li className="nav-item pt-2"><Link to="/meter-reading-schedule">{languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}</Link></li>*/}
            {/*              </ul>*/}
            {/*          </li>*/}
            {/*          <li className="nav-item"><Link to="/download" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Download' : `डाउनलोड`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/gallery" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/team" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Members' : `सदस्य`}*/}
            {/*          </Link></li>*/}
            {/*          <li className="nav-item"><Link to="/contact" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>*/}
            {/*              {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}*/}
            {/*          </Link></li>*/}
            {/*        </ul>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="language_buttons">*/}
            {/*      <Link*/}
            {/*          onClick={() => changeLanguage('en')}*/}
            {/*          className="mr-1"*/}
            {/*      >*/}
            {/*          <div*/}
            {/*              className=""*/}
            {/*              style={{*/}
            {/*                  cursor: 'pointer',*/}
            {/*                  outline: languageStatus === 'en' ? '2px solid white' : ''*/}
            {/*              }}*/}
            {/*          >*/}
            {/*              <img*/}
            {/*                  src={EnglishFlag}*/}
            {/*                  width="36"*/}
            {/*                  height="36"*/}
            {/*                  className="el-image uk-svg uk-preserve"*/}
            {/*                  alt=""*/}
            {/*                  uk-svg=""*/}
            {/*                  hidden=""*/}
            {/*              />*/}
            {/*          </div>*/}
            {/*      </Link>*/}
            {/*      <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">*/}
            {/*          <div*/}
            {/*              className="uk-button-link uk-border-rounded uk-box-shadow-small"*/}
            {/*              style={{*/}
            {/*                  cursor: 'pointer',*/}
            {/*                  outline: languageStatus === 'np' ? '2px solid white' : ''*/}
            {/*              }}*/}
            {/*          >*/}
            {/*                  <img*/}
            {/*                      src={NepaliFlag}*/}
            {/*                      width="36"*/}
            {/*                      height="36"*/}
            {/*                      className="el-image uk-svg uk-preserve"*/}
            {/*                      alt=""*/}
            {/*                      uk-svg=""*/}
            {/*                      hidden=""*/}
            {/*                  />*/}
            {/*          </div>*/}
            {/*      </Link>*/}
            {/*    </div>*/}
            {/*  </nav>*/}

            <nav className="navbar navbar-expand-lg bg-light shadow-lg p-0">
                <div className="container">
                  <Link className="navbar-brand" to="/">
                      <img
                          src={
                              orgDetailData &&
                              orgDetailData.logoPath &&
                              orgDetailData.logoPath !== ""
                                  ? orgDetailData.logoPath
                                  : null
                          }
                          alt=""
                          className="logo img-fluid"
                      />
                  </Link>
                  <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#ftco-nav"
                      aria-controls="ftco-nav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                  >
                    <span className="oi oi-menu"></span> {languageStatus === 'en' ? 'Menu' : `मेनु`}
                  </button>

                  <div className="collapse navbar-collapse" id="ftco-nav">
                    <ul className="nav ml-auto">
                      <li className="nav-item" onMouseOver={handleShowDropdown}><Link
                          to="/"
                          className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}
                      >{languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}</Link></li>
                      <li
                          className="dropdown nav-item"
                          onClick={handleToggleDropdown}
                          onMouseOver={handleShowDropdown}
                          onMouseLeave={handleHideDropdown}
                      >
                          <a
                              className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}
                              href="#"
                          >{languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`} <b className="fa fa-chevron-down"></b></a>
                          <ul className="dropdown-menu px-3">
                            <li className="nav-item pt-2"><Link to="/about">{languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}</Link></li>
                              {Object.keys(messages).length > 0
                                  ? Object.keys(messages).map((key) => {
                                      return (
                                          <li className="nav-item pt-2" key={key}>
                                              <Link to={`/messages/${key}`}>
                                                  {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}
                                              </Link>
                                          </li>
                                      );
                                  })
                                  : null}
                          </ul>
                      </li>
                        <li
                            className="dropdown nav-item"
                            onClick={handleToggleDropdown}
                            onMouseOver={handleShowDropdown}
                            onMouseLeave={handleHideDropdown}
                        >
                          <a
                              className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}
                              href="#"
                          >{languageStatus === 'en' ? 'News/Notices' : `समाचार/सुचना`} <b className="fa fa-chevron-down"></b></a>
                          <ul className="dropdown-menu px-3">
                            <li className="nav-item pt-2"><Link to="/news">{languageStatus === 'en' ? 'News' : `समाचार`}</Link></li>
                            <li className="nav-item pt-2"><Link to="/notice">{languageStatus === 'en' ? 'Notices' : `सुचना`}</Link></li>
                          </ul>
                      </li>
                        <li
                            className="dropdown nav-item"
                            onClick={handleToggleDropdown}
                            onMouseOver={handleShowDropdown}
                            onMouseLeave={handleHideDropdown}
                        >
                          <a
                              className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}
                              href="#"
                          >{languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`} <b className="fa fa-chevron-down"></b></a>
                          <ul className="dropdown-menu px-3">
                            <li className="nav-item pt-2"><Link to="/our-services">{languageStatus === 'en' ? 'Our Services' : `हाम्रो सेवाहरु`}</Link></li>
                            <li className="nav-item pt-2"><Link to="/outstanding-report">{languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}</Link></li>
                            <li className="nav-item pt-2"><Link to="/complain-service">{languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}</Link></li>
                            <li className="nav-item pt-2"><Link to="/water-schedule">{languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}</Link></li>
                            <li className="nav-item pt-2"><Link to="/meter-reading-schedule">{languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}</Link></li>
                          </ul>
                      </li>
                      <li className="nav-item"><Link to="/download" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>
                          {languageStatus === 'en' ? 'Download' : `डाउनलोड`}
                      </Link></li>
                      <li className="nav-item"><Link to="/gallery" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>
                          {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}
                      </Link></li>
                      <li className="nav-item"><Link to="/team" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>
                          {languageStatus === 'en' ? 'Members' : `सदस्य`}
                      </Link></li>
                      <li className="nav-item"><Link to="/contact" className={languageStatus === 'en' ? 'nav-link nav-eng-nep' : `nav-link`}>
                          {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}
                      </Link></li>
                    </ul>
                  </div>
                </div>
                <div className="language_buttons">
                  <Link
                      onClick={() => changeLanguage('en')}
                      className="mr-1"
                  >
                      <div
                          className=""
                          style={{
                              cursor: 'pointer',
                              outline: languageStatus === 'en' ? '2px solid white' : ''
                          }}
                      >
                          <img
                              src={EnglishFlag}
                              width="36"
                              height="36"
                              className="el-image uk-svg uk-preserve"
                              alt=""
                              uk-svg=""
                              hidden=""
                          />
                      </div>
                  </Link>
                  <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">
                      <div
                          className="uk-button-link uk-border-rounded uk-box-shadow-small"
                          style={{
                              cursor: 'pointer',
                              outline: languageStatus === 'np' ? '2px solid white' : ''
                          }}
                      >
                              <img
                                  src={NepaliFlag}
                                  width="36"
                                  height="36"
                                  className="el-image uk-svg uk-preserve"
                                  alt=""
                                  uk-svg=""
                                  hidden=""
                              />
                      </div>
                  </Link>
                </div>
              </nav>
        </>
    );
}

export default Navbar;
