import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";

function About() {
    const {
        fetchAboutUs,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const languageStatus = sessionStorage.getItem("language");
    const [aboutUsData, setAboutUsData] = useState([]);
    const [messages, setMessages] = useState({});
    const [messageDetails, setMessageDetails] = useState({});

    const getAboutUs = async () => {
        try {
            const response = await fetchAboutUs();
            setAboutUsData(response.data.aboutUsDetails);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId) {
            getAboutUs();
        }
    }, [organizationId]);

    useEffect(() => {
        if (aboutUsData.length > 0) {
            aboutUsData.length = 1;
            setAboutUsData(aboutUsData);
        }
    }, [aboutUsData]);

    return (
        <>
      <section className="ftco-section ftco-no-pb ftco-no-pt mt-2">
		<div className={window.location.pathname === '/' ? `container` : 'container about-container'}>
			<div className="row no-gutters">
				<div className="col-md-4 d-flex align-items-stretch">
					<div
                        className={`img img-3 w-100 d-flex justify-content-center align-items-center ` + (window.location.pathname === '/' ? 'about-image-home' : `about-image-home`)}
                        style={{
                            backgroundImage: aboutUsData[0]?.image && aboutUsData[0]?.image.trim() !== "" ? `url(${aboutUsData[0]?.image})` : "url(assets/images/placeholder.jpg)",
                            position: "relative"
                        }}
                    >
					</div>
				</div>
				<div className="col-md-8 wrap-about ftco-animate">
					<div className="bg-light px-3 px-md-4 py-5 ">
						<div className="heading-section">
							<h2 className="mb-3">
								{
                                    languageStatus === 'en' ?
                                        (aboutUsData[0]?.header ? aboutUsData[0]?.header : aboutUsData[0]?.headerNp)
                                        : (aboutUsData[0]?.headerNp ? aboutUsData[0]?.headerNp : aboutUsData[0]?.header)
                                }
							</h2>

							<p
                                className="text-justify"
                                dangerouslySetInnerHTML={parseHtmlToView(
                                    (languageStatus === 'en' ? (aboutUsData[0]?.message !== '' ? aboutUsData[0]?.message : aboutUsData[0]?.messageNp) : ((aboutUsData[0]?.messageNp !== '' && aboutUsData[0]?.messageNp) ? aboutUsData[0]?.messageNp : aboutUsData[0]?.message))
                                )}
                            ></p>
						</div>
					</div>

				</div>
			</div>
		</div>
	</section>
    </>
    );
}

export default About;
