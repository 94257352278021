import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ApiService from "../../../network/ApiService";

function Service() {
  const { organizationId } = useSelector((state) => state.organization);
  const {
    fetchServices,
  } = ApiService();

  const [serviceList, setServiceList] = useState([]);
  const languageStatus = sessionStorage.getItem("language");
  const getServices = async () => {
    try {
      const response = await fetchServices();
      setServiceList(response.data.messageWithImg);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getServices();
    }
  }, [organizationId]);

  return (
    <section id="services" className="services">
        <div className="container mt-5">
          <div className="section-title">
            <h2>{ languageStatus === 'en' ? `Our Services` : `हाम्रा सेवाहरू` }</h2>
          </div>

          {serviceList.length > 0 ? (
          <div className="row">
            {serviceList.map((data, index) => {
              return (
                <div
                  className="col-lg-4 col-md-6 d-flex align-items-stretch mt-3"
                  key={index}
                >
                  <div className="icon-box">
                    <Link to={`/service-detail/${data.id}`} style={{ display: 'flex', flexDirection:'column', alignItems: 'center' }}>
                      <img
                        src={
                          data.imageUrl !== "n/a"
                            ? data.imageUrl
                            : `assets/images/placeholder.jpg`
                        }
                        className="news-images"
                      />
                      <h4>{ languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header) }</h4>
                      <p
                        dangerouslySetInnerHTML={parseHtmlToView(
                            (languageStatus === 'en' ? (data.message !== '' ? data.message : data.messageNp) : ((data.messageNp !== '' && data.messageNp) ? data.messageNp : data.message))
                                .split("...").join("").substring(0, 100)
                        )}
                      ></p>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
      ) : <>
        <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
              <div
                  className="col-md-12"
                  style={{fontSize: '24px'}}
              >
                { languageStatus === 'en' ? `Services Not Found` : `सेवाहरू भेटियन`}...
              </div>
            </div>
      </>}
        </div>
    </section>
  );
}

export default Service;
