import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../network/ApiService";
import engToNepNumber from "../../helpers/EngToNepNumber";
import {Link} from "react-router-dom";

function DownloadDetails({detailId, setAboutUsId}) {
    const {
        fetchDownloads,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [downloadDocuments, setDownloadDocuments] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const getDownloadDocuments = async () => {
        try {
            const response = await fetchDownloads();
            setDownloadDocuments(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId) {
            getDownloadDocuments();
        }
    }, [organizationId]);

    return (
        <>
      <main id="main" className="mb-4 mt-4">
        <section id="about" className="about">
          <div className="container">
            <div className="section-title d-flex justify-content-between">
              <h2>{languageStatus === 'en' ? (downloadDocuments[0]?.header ?? downloadDocuments[0]?.headerNp ) + ` - Download` : (downloadDocuments[0]?.headerNp ?? downloadDocuments[0]?.header ) + ` - डाउनलोड`}</h2>
                <Link onClick={() => setAboutUsId(null)} style={{cursor :"pointer", display: 'inline-flex', gap: '0.25rem', alignItems:'center'}}><h4><i className="fa fa-arrow-left"></i></h4> {languageStatus === 'en' ? `Back` : 'फिर्ता'}</Link>
            </div>
            <div className="row no-gutters">
              <div className="col-md-12 d-flex align-items-stretch">
                <table className="table table-striped">
                    <thead>
                        <th>{ languageStatus === 'en' ? 'S.No' : 'क्रम संख्या'}</th>
                        <th>{ languageStatus === 'en' ? 'Document Name' : 'कागजातको नाम'}</th>
                        <th>{ languageStatus === 'en' ? 'Download' : 'डाउनलोड'}</th>
                    </thead>
                  <tbody>
                  {downloadDocuments.length > 0
                      ? downloadDocuments.filter((a) => {
                          return a.aboutUsId === detailId
                      }).map((data, index) => {
                          return (
                              data.documentWithNameList.length > 0 ?
                                  (data.documentWithNameList).map((image, imageIndex) => {
                                      return (
                                          <>
                                      <tr key={index}>
                                          <th>{languageStatus === 'en' ? imageIndex + 1 : engToNepNumber(imageIndex + 1)}</th>
                                          {/* {console.log(data.message,'sddfn')} */}
                                          <td>{languageStatus === 'en' ? (image.docName ?? image.docNameNp ?? 'N/A') : (image.docNameNp ?? image.docName ?? 'नाम भेटियन')}</td>
                                          <td>
                                              <a
                                                  href={image.docUrl} target="_blank"
                                                  style={{
                                                      fontSize: "20px",
                                                  }}
                                                  download={image.docUrl}
                                              >
                                                  <i className="fa fa-arrow-down"></i>
                                              </a>
                                          </td>
                                      </tr>
                                  </>
                                      )
                                  })
                                  : null
                          );
                      })
                      : <tr>
                              <td
                                  colSpan={4}
                                  className="uk-text-bold"
                                  style={{fontSize: '20px'}}
                              >
                                  {languageStatus === 'en' ? `No File Available for Download` : `डाउनलोड फाइल भेटियन`}...
                              </td>
                            </tr>
                  }
                  </tbody>
                </table>
              </div>
              <div className="col-md-2 d-flex align-items-stretch">
                {/* <News newsDetails={true} />  */}
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
    );
}

export default DownloadDetails;
