import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import ApiService from "../../../network/ApiService";

function ServiceDetail() {
    const {
        fetchServiceDetailById,
        fetchServices,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [serviceDetail, setServiceDetail] = useState([]);
    const languageStatus = sessionStorage.getItem("language");
    const params = useParams();
    const serviceId = params.serviceId;

    const [serviceList, setServiceList] = useState([]);
    const [slicedService, setSlicedService] = useState([]);
    const getServices = async () => {
        try {
            const response = await fetchServices();
            setServiceList(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const getServiceDetails = async () => {
        try {
            const response = await fetchServiceDetailById(serviceId);
            setServiceDetail(response.data.messageWithImg);
        } catch (error) {
            console.log(error.message);
        }
    };

    const parseHtmlToView = (str) => {
        return {__html: str};
    };

    useEffect(() => {
        if (organizationId && serviceId) {
            getServiceDetails();
            getServices();
        }
    }, [organizationId, serviceId]);

    useEffect(() => {
        if (serviceList.length > 0 && serviceId) {
            const newList = [...serviceList];
            const filtered = newList.filter((item) => {
                return item.id !== Number(serviceId);
            });

            if (filtered.length > 3) {
                filtered.length = 3;
            }
            setSlicedService(filtered);
        }
    }, [serviceList]);

    return (
        <section id="services" className="services">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-12 mt-3">
            <div className="section-title">
              <h2>{ languageStatus === 'en' ? `Our Services` : `हाम्रा सेवाहरू` }</h2>
            </div>
              {serviceDetail.length > 0 ? (
                  <div className="news-block">
                <div className="news-block-top">
                  <a href="#">
                    <img
                        src={
                            serviceDetail[0].imageUrl !== "n/a"
                                ? serviceDetail[0].imageUrl
                                : `assets/images/placeholder.jpg`
                        }
                        className="news-image img-fluid"
                        alt=""
                    />
                  </a>
                </div>

                <div className="news-block-info">
                  <div className="news-block-title mb-2">
                    <h4>
                      <Link to={`/service-detail/${serviceDetail[0].id}`} className="news-block-title-link">
                        {serviceDetail[0] ? ( languageStatus === 'en' ? (serviceDetail[0].header ?? serviceDetail[0].headerNp) : (serviceDetail[0].headerNp ?? serviceDetail[0].header)) : null}
                      </Link>
                    </h4>
                  </div>

                  <div className="news-block-body">
                    <p
                        dangerouslySetInnerHTML={parseHtmlToView(
                            (languageStatus === 'en' ? (serviceDetail[0]?.message !== '' ? serviceDetail[0]?.message : serviceDetail[0]?.messageNp) : ((serviceDetail[0]?.messageNp !== '' && serviceDetail[0]?.messageNp) ? serviceDetail[0]?.messageNp : serviceDetail[0]?.message))
                        )}
                    ></p>
                  </div>
                </div>
              </div>
              ) : null}
          </div>

          <div className="col-lg-4 col-12">
            {/* <h5 className="mt-5 mb-3">भर्खरको समाचार</h5> */}
              {slicedService.length > 0 ? (
                  <div className="container mt-3">
                <div className="section-title">
                  <h2>{languageStatus === 'en' ? 'Other Services' : `अन्य सेवाहरु`}</h2>
                </div>

                <div className="row">
                  {slicedService.map((data, index) => {
                      return (
                          <div
                              className="col-lg-12 col-md-6 d-flex align-items-stretch mt-3"
                              key={index}
                          >
                        <div className="icon-box">
                          <Link to={`/service-detail/${data.id}`}>
                            <img
                                src={
                                    data.imageUrl !== "n/a"
                                        ? data.imageUrl
                                        : `assets/img/features.jpg`
                                }
                                className="news-images"
                            />
                            <h4>{( languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header))}</h4>
                            {/*<p*/}
                            {/*    dangerouslySetInnerHTML={parseHtmlToView(*/}
                            {/*        data.message*/}
                            {/*            .split("...")*/}
                            {/*            .join("")*/}
                            {/*            .substring(0, 100)*/}
                            {/*    )}*/}
                            {/*></p>*/}
                          </Link>
                        </div>
                      </div>
                      );
                  })}
                </div>
              </div>
              ) : null}
          </div>
        </div>
      </div>
    </section>
    );
}

export default ServiceDetail;
