import React from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function Hero() {
    const {orgDetailData} = useSelector((state) => state.contact);
    const languageStatus = sessionStorage.getItem("language");

    return (
        <>
            <div
                className="hero-wrap"
                style={{backgroundImage: "url('/assets/images/bg_1.jpg')"}}
                data-stellar-background-ratio="0.5"
            >
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center">
                        <div className="col-11 ftco-animate d-flex align-items-end">
                            <div className="text w-100">
                                <h1 className="mb-4">
                                    {
                                        orgDetailData ? (languageStatus === 'en' ? (orgDetailData.nameEnglish ?? orgDetailData.nameNepali) : (orgDetailData.nameNepali ?? orgDetailData.nameEnglish)) : null
                                    }
                                </h1>
                            </div>
                        </div>
                        {/*<Link*/}
                        {/*    to="/about"*/}
                        {/*    className="img-video popup-vimeo d-flex align-items-center justify-content-center"*/}
                        {/*>*/}
                        {/*    <span className="fa fa-play"></span>*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Hero;
