import {Route, Routes, useLocation,} from "react-router-dom";
// import "./App.css";
import Footer from "./ui/partial/Footer";
import Header from "./ui/partial/Header";
import Home from "./ui/Home";
import About from "./ui/pages/About";
import Notices from "./ui/pages/notices/Notices";
import {useEffect, useState} from "react";
// import NewsDetail from "./ui/pages/news/NewsDetail";
import Contact from "./ui/pages/Contact";
import {useDispatch, useSelector} from "react-redux";
import {setOrganizationId} from "./state/reducers/OrganizationReducer";
import ApiService from "./network/ApiService";
import NotFoundPage from "./ui/pages/NotFoundPage";
import Download from "./ui/pages/Download";
import Message from "./ui/pages/Message";
import Service from "./ui/pages/services/Service";
import ServiceDetail from "./ui/pages/services/ServiceDetail";
import ScheduleList from "./ui/pages/eservices/waterSchedule/ScheduleList";
import ScheduleDetail from "./ui/pages/eservices/waterSchedule/ScheduleDetail";
import OutStandingReport from "./ui/pages/eservices/outstandingReport/OutStandingReport";
import MeterScheduleList from "./ui/pages/eservices/readingSchedule/MeterScheduleList";
// import PaymentGateway from "./ui/pages/eservices/paymentGateway/PaymentGateway";
import Team from "./ui/pages/teams/Team";
import Gallery from "./ui/pages/gallery/Gallery";
import {setContactListData, setOrgDetailData,} from "./state/reducers/ContactListReducers";
import Complain from "./ui/pages/eservices/complain/Complain";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {setPopUpData} from "./state/reducers/PopupReducer";
import axios from "axios";
import News from "./ui/pages/news/News";
import NewsDetail from "./ui/pages/news/NewsDetail";
import NoticeDetail from "./ui/pages/notices/NoticeDetail";

function App() {
  // const [sidebarTop, setSidebarTop] = useState(undefined);
  const [contactList, setContactList] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isOrgIdChange, setIsOrgIdChange] = useState(false);
  // const [popUp, setPopUp] = useState([]);
  // const [params] = useSearchParams();
  const dispatch = useDispatch();
  // const orgId = params.get("id");
  const hostName = window.location.hostname;
  // const hostName = "www.rajaharkhanepani.org.np";
  // const hostName = "www.mangadhkhanepani.org.np";
  const domainName = hostName.replace("www.", "");
  const { organizationId } = useSelector((state) => state.organization);
  const { contactListData, orgDetailData } = useSelector(
    (state) => state.contact
  );

  const { popUpData } = useSelector((state) => state.popUp);

  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchOrganizationDetails,
    fetchOrgIdByDomainName,
    fetchPopup,
  } = ApiService();

  const getContactList = async () => {
    try {
      const response = await fetchContact();
      // setContactList(response.data.contactsList);
      dispatch(setContactListData(response.data.contactsList));
    } catch (error) {
      console.log(error.message);
    }
  };

  const getOrgIdByDomainName = async () => {
    try {
      const response = await fetchOrgIdByDomainName(domainName);
      // response.data = 21;
      if (organizationId !== response.data) {
        setIsOrgIdChange(true);
      }
      if (organizationId !== response.data) {
        dispatch(setContactListData(null));
        dispatch(setOrgDetailData(null));
      }
      dispatch(setOrganizationId(response.data));
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong, Please Contact System Administrator");
    }
  };

  const getOrganizationDetails = async () => {
    try {
      const response = await fetchOrganizationDetails();
      // setOrganizationDetails(response.data);
      dispatch(setOrgDetailData(response.data));
    } catch (error) {
      console.log(error.message);
    }
  };

  const getPopUp = async () => {
    try {
      const response = await fetchPopup();
      // setPopUp(response.data.messageWithImg);
      dispatch(setPopUpData(response.data.messageWithImg));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getOrgIdByDomainName(domainName);
  }, [domainName]);

  // useEffect(() => {
  //   if (orgId && orgId !== organizationId) {
  //     dispatch(setOrganizationId(orgId));
  //     dispatch(setContactListData(null));
  //     dispatch(setOrgDetailData(null));
  //   }
  // }, [orgId]);

  useEffect(() => {
    if (organizationId) {
      if (!contactListData || isOrgIdChange) {
        getContactList();
      }
      if (!orgDetailData || isOrgIdChange) {
        getOrganizationDetails();
      }
      if ((popUpData && popUpData.length < 1) || isOrgIdChange) {
        getPopUp();
      }

      const languageStatus = sessionStorage.getItem("language");

      document.title = languageStatus === 'en' ? (orgDetailData?.nameEnglish ?? orgDetailData?.nameNepali) : (orgDetailData?.nameNepali ?? orgDetailData?.nameEnglish)
    }
  }, [organizationId, isOrgIdChange]);

  useEffect(() => {
    if (contactListData) {
      setContactList(contactListData);
    }
  }, [contactListData]);

  useEffect(() => {
    if (orgDetailData) {
      setOrganizationDetails(orgDetailData);
    }
  }, [orgDetailData]);

  const location = useLocation();
  // useEffect(() => {
  //   document.documentElement.scrollTo(0, 0);
  // }, [location.pathname]);
  //
  // useEffect(() => {
  //   const sidebarEl = document.querySelector('.sticky_header')?.getBoundingClientRect() ?? 0;
  //   setSidebarTop(sidebarEl.top);
  // }, []);

  // useEffect(() => {
  //   if (!sidebarTop) return;
  //   window.addEventListener('scroll', isSticky);
  //   return () => {
  //     window.removeEventListener('scroll', isSticky);
  //   };
  // }, [sidebarTop]);

  // const isSticky = (e) => {
  //   const sidebarEl = document.querySelector('.sticky_header');
  //   const scrollTop = window.scrollY;
  //   if (scrollTop >= sidebarTop - 1) {
  //     sidebarEl.classList.add('is-sticky');
  //   } else {
  //     sidebarEl.classList.remove('is-sticky');
  //   }
  // }

  return (
    <div className="">
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/messages/:messageId" element={<Message />} />
        <Route path="/team" element={<Team />} />
        <Route path="/news" element={<News />} />
        <Route path="/news-detail/:newsId" element={<NewsDetail />} />
        <Route path="/notice" element={<Notices />} />
        <Route path="/notice-detail/:newsId" element={<NoticeDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/download" element={<Download />} />
        <Route path="/gallery" element={<Gallery />}/>
        <Route path="/our-services" element={<Service />} />
        <Route path="/service-detail/:serviceId" element={<ServiceDetail />} />
        <Route path="/outstanding-report" element={<OutStandingReport />} />
        {/*<Route path="/payment-gateway" element={<PaymentGateway />} />*/}
        <Route path="/water-schedule" element={<ScheduleList />} />
        <Route
          path="/water-schedule-detail/:waterScheduleId"
          element={<ScheduleDetail />}
        />
        <Route path="/meter-reading-schedule" element={<MeterScheduleList />} />
        <Route path="/complain-service" element={<Complain />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
//
// export function CheckImage(path) {
//   axios
//       .get(path)
//       .then(() => {
//         return true;
//       })
//       .catch(() => {
//         return false;
//       });
// }
