import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

function Footer() {
    const {contactListData, orgDetailData} = useSelector(
        (state) => state.contact
    );
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [orgDetail, setOrgDetail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");

    useEffect(() => {
        if (contactListData && Object.keys(contactListData).length > 0) {
            setPhone(contactListData.find((item) => item.contactType === "Phone"));
            setEmail(contactListData.find((item) => item.contactType === "Email"));
        }
    }, [contactListData]);

    useEffect(() => {
        if (orgDetailData && Object.keys(orgDetailData).length > 0) {
            setOrgDetail(orgDetailData);
        }
    }, [orgDetailData]);

    // const parseHtmlToView = (str) => {
    //   return { __html: str };
    // };

    return (
        <>
            <footer className="ftco-footer">
              <div className="container">
                <div className="row mb-5">
                  <div className="col-sm-12 col-md">
                    <div className="ftco-footer-widget mb-4">
                        <h2 className="ftco-heading-2">{languageStatus === 'en' ? `Contact` : `सम्पर्क`}</h2>
                        <div className="block-23 mb-3">
                          <ul>
                            <li><span className="icon fa fa-map marker"></span><span className="text">
                                {orgDetail ? (languageStatus === 'en' ? (orgDetail?.nameEnglish ?? orgDetail?.nameNepali) : (orgDetail?.nameNepali ?? orgDetail?.nameEnglish)) : null} <br />
                                {orgDetail ? (languageStatus === 'en' ? (orgDetail?.addressEnglish ?? orgDetail?.addressNepali) : (orgDetail?.addressNepali ?? orgDetail?.addressEnglish)) : null} <br />
                            </span></li>
                            <li><a href={`tel:${orgDetail ? orgDetail?.contactNumberNp : ""}`}>
                                <span className="icon fa fa-phone"></span><span className="text">{orgDetail ? (languageStatus === 'en' ? (orgDetail?.contactNumber ?? orgDetail?.contactNumberNp) : (orgDetail?.contactNumberNp ?? orgDetail?.contactNumber)) : null}</span></a></li>
                            <li><a href={`mailto:${email?.contactAddress}`}><span className="icon fa fa-paper-plane pr-4"></span><span
                                className="text"
                            >{email?.contactAddress}</span></a></li>
                          </ul>
                        </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md">
                    <div className="ftco-footer-widget mb-4 ml-md-4">
                      <h2 className="ftco-heading-2">{languageStatus === 'en' ? `Link` : `लिङ्क`}</h2>
                      <ul className="list-unstyled">
                          <li>
                          <Link to="/about">
                              <span className="fa fa-chevron-right mr-2" />
                              {languageStatus === 'en' ? `Organization Information` : `संस्थाको जानकारी`}
                          </Link>
                          </li>
                          <li>
                          <Link to="/news">
                              <span className="fa fa-chevron-right mr-2" />
                              {languageStatus === 'en' ? `News & Notices` : `सुचना/गतिविधि`}
                          </Link>
                          </li>
                          <li>
                          <Link to="/contact">
                              <span className="fa fa-chevron-right mr-2" />
                              {languageStatus === 'en' ? `Contact` : `सम्पर्क`}
                          </Link>
                          </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md">
                    <div className="ftco-footer-widget mb-4 ml-md-4">
                      <h2 className="ftco-heading-2">{languageStatus === 'en' ? `Other Links` : `अतिरित्त लिङ्क`}</h2>
                      <ul className="list-unstyled">
                        <li>
                        <a href="http://www.mowss.gov.np/" target="_blank">
                            <span className="fa fa-chevron-right mr-2" />
                            {languageStatus === 'en' ? `Ministry of Water Supply` : `खानेपानी मन्त्रालय`}
                        </a>
                      </li>
                      <li>
                        <a href="http://www.nwsc.gov.np/" target="_blank">
                            <span className="fa fa-chevron-right mr-2" />
                            {languageStatus === 'en' ? `Department of Water Supply` : `खानेपानी बिभाग`}
                        </a>
                      </li>
                      <li>
                        <a href="http://www.tdf.org.np/" target="_blank">
                            <span className="fa fa-chevron-right mr-2" />
                            {languageStatus === 'en' ? `Municipal Development Fund` : `नगर विकास कोष`}
                        </a>
                      </li>
                      </ul>
                    </div>
                  </div>
                    <div className="col-sm-12 col-md">
                     <div className="ftco-footer-widget mb-4">
                      <h2 className="ftco-heading-2">{languageStatus === 'en' ? `Social Links` : `सामाजिक लिङ्कहरू`}</h2>
                      <div className="ftco-footer-social list-unstyled mt-2">
                          <p className="mb-0 d-flex">
                              <li className="ftco-animate fadeInUp ftco-animated">
                              <a
                                  href={orgDetail ? orgDetail?.facebookUrl : ""}
                                  className="d-flex align-items-center justify-content-center"
                                  target="_blank"
                              ><span
                                  className="fa fa-facebook"
                              ><i className="sr-only">{languageStatus === 'en' ? `Facebook` : 'फेसबुक'}</i></span></a>
                              </li>
                              <li className="ftco-animate fadeInUp ftco-animated">
                              <a
                                  href={orgDetail ? orgDetail?.twitterUrl : ""}
                                  className="d-flex align-items-center justify-content-center"
                                  target="_blank"
                              ><span
                                  className="fa fa-twitter"
                              ><i className="sr-only">{languageStatus === 'en' ? `Twitter` : 'ट्विटर'}</i></span></a>
                              </li>
                              <li className="ftco-animate fadeInUp ftco-animated">
                              <a
                                  href={orgDetail ? orgDetail?.linkedInUrl : ""}
                                  className="d-flex align-items-center justify-content-center"
                                  target="_blank"
                              ><span
                                  className="fa fa-linkedin"
                              ><i className="sr-only">{languageStatus === 'en' ? `LinkedIn` : 'लिङ्क्डइन'}</i></span></a>
                              </li>
                              <li className="ftco-animate fadeInUp ftco-animated">
                              <a
                                  href={orgDetail ? orgDetail?.youtubeUrl : ""}
                                  className="d-flex align-items-center justify-content-center"
                                  target="_blank"
                              ><span
                                  className="fa fa-youtube"
                              ><i className="sr-only">{languageStatus === 'en' ? `YouTube` : 'युट्युब'}</i></span></a>
                              </li>
                          </p>
                      </div>
                     </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid px-0 py-2 bg-black">
                <div className="">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                            &copy;
                            {new Date().getFullYear()}{" "} {orgDetail ? (languageStatus === 'en' ? (`All Rights Reserved ` + orgDetail?.nameEnglish) : (orgDetail?.nameNepali + `| सम्पूर्ण अधिकार सुरक्षित`)) : null}
                        </div>
                      <div className="col-md-6 text-right">
                        <p className="mb-0" style={{color: "rgba(255,255,255,.5)"}}>
                            {languageStatus === 'en' ? 'Designed and Developed By : ' : `व्यबसाईट निर्माता : `} <a
                            target="_blank"
                            href="https://diyalotech.com/"
                        >{languageStatus === 'en' ? `Diyalo Technologies` : ` दियालो टेक्नोलोजी`}</a>
                            </p>
                      </div>
                    </div>
                </div>
              </div>
            </footer>
            <style type="text/css">
                { orgDetailData?.customCss}
            </style>
            </>
    );
}

export default Footer;
